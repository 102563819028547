<template>
  <div class="md:w-1/2 sm:w-full mx-auto">
    <div class="flex flex-wrap -mx-2 space-y-4 md:space-y-0">
      <div class="mt-8 mx-auto max-w-7xl">
        <div class="text-center w-full h-auto">
          <h1
            class="
              text-4xl
              tracking-tight
              font-extrabold
              text-gray-900
              sm:text-5xl
              md:text-6xl
            "
          >
            <span class="block">Now to perfect your</span>
            <span class="block text-mygrad">GraduationClip</span>
          </h1>
          <p
            class="
              mt-3
              max-w-md
              mx-auto
              text-base text-gray-700
              sm:text-lg
              md:mt-5 md:text-xl md:max-w-3xl
            "
          >
            Moving the <b>left slider</b> to after you have taken your
            <b>first two steps</b>.
          </p>
          <p
            class="
              mt-3
              max-w-md
              mx-auto
              text-base text-gray-700
              sm:text-lg
              md:mt-5 md:text-xl md:max-w-3xl
            "
          >
            Move the <b>right slider</b> to the moment you last appear on
            screen.
          </p>
        </div>
      </div>
      <div v-if="this.errors.length" class="mt-8 mx-auto max-w-7xl">
        <div class="text-center w-full h-auto">
          <p
            class="
              mt-3
              max-w-md
              mx-auto
              text-base text-red-500
              sm:text-lg
              md:mt-5 md:text-xl md:max-w-3xl
            "
          >
            {{ this.errors[0] }}
          </p>
        </div>
      </div>
      <div class="mt-8 mx-auto max-w-7xl">
        <div class="text-center w-full h-auto">
          <video-player
            :options="videoOptions"
            ref="videoPlayer"
            @statechanged="playerStateChanged"
            @loadeddata="onPlayerReady"
          ></video-player>
          <video-slider
            v-if="playerReady"
            :maxThreshold="this.maxThreshold"
            :minThreshold="this.minThreshold"
            :min="this.min"
            :max="this.max"
            @update:min="changeIn"
            @update:max="changeOut"
          ></video-slider>
        </div>
      </div>
      <div class="text-center w-full h-auto">
        <input type="checkbox" id="checkbox" v-model="include_audio" />
        <label for="checkbox" class="
            px-4
            py-2"
          >Include the announcment of my name</label
        >
      </div>
      <div class="text-center w-full h-auto">
        <button
          @click="submit"
          type="submit"
          class="
            inline-flex
            items-center
            px-4
            py-2
            border border-gray-300
            shadow-sm
            text-sm
            font-medium
            rounded-md
            text-gray-700
            bg-white
            hover:bg-gray-50
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-indigo-500
            disabled:opacity-50!important
            disabled:hidden!important
          "
          :disabled="!(this.hasMovedIn && this.hasMovedOut)"
        >
          Submit
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import 'videojs-contrib-hls'
import 'video.js/dist/video-js.css'
import VideoSlider from "../VideoSlider.vue";

export default {
  name: "ManualSubmissionPage",
  props: {
    focalPoint: Number,
    ceremony: Object,
    errors: Array,
  },
  data: function () {
    return {
      hasMovedIn: false,
      hasMovedOut: false,
      video: this.ceremony.video,
      videoOptions: {
        autoplay: false,
        preload: "auto",
        playToggle: false,
        controlBar: {
          playToggle: false,
          currentTimeDisplay: false,
          durationDisplay: false,
          timeControlDisplay: false,
          remainingTimeDisplay: false,
          progressControl: {
            seekBar: false,
          },
          fullscreenToggle: false,
          muteToggle: false,
          volumeBar: false,
          playbackRateMenuButton: false,
        },
        sources: [{
          type: "application/x-mpegURL",
          src: this.ceremony.video,
        }],
        aspectRation: "16:9",
        responsive: true,
      },
      min: this.focalPoint - 4,
      max: this.focalPoint + 10,
      include_audio: true,
      playerReady: false,
    };
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player;
    },
    minThreshold() {
      return this.focalPoint - 4;
    },
    maxThreshold() {
      return this.focalPoint + 10;
    },
  },
  methods: {
    changeIn(value) {
      this.min = parseFloat(value);
      this.hasMovedIn = true;
      console.log("IN" + value);
      this.player.currentTime(value);
      this.player.pause();
    },
    changeOut(value) {
      this.max = parseFloat(value);
      this.hasMovedOut = true;
      this.player.currentTime(value);
      this.player.pause();
    },
    onPlayerReady(player) {
      console.log("Pkayer Ready");
      this.playerReady = true;
      this.player.pause();
      player.currentTime(this.minThreshold);
    },
    onPlayerPlay(player) {
      console.log("player play!", player);
    },
    onPlayerPause(player) {
      console.log("player pause!", player);
      console.log("Current time: ", player.currentTime());
    },
    playerStateChanged(event) {
      console.log("change", event);
      if ("loadeddata" in event && event.loadeddata) {
        this.onPlayerReady(this.player);
      }
      if ("pause" in event && event.pause) {
        this.onPlayerPause(this.player);
      } else if ("play" in event && event.play) {
        this.onPlayerPlay(this.player);
      }
      console.log("change", event);
    },
    submit() {
      this.$emit("submit", {
        min: this.min,
        max: this.max,
        include_audio: this.include_audio,
      });
    },
  },
  components: {
    VideoSlider,
  },
};
</script>
<style>
video {
  min-width: 100%;
  position: relative !important;
}

.video-js .vjs-time-divider {
  display: none !important;
}
button:disabled {
  visibility: hidden;
}
.video-js {
  width: 100% !important;
  height:auto;
  position: relative !important;
}
</style>