<template>
  <div class="container mx-auto px-4">
    <div class="text-center mt-8">
      <h1
        class="
          text-4xl
          tracking-tight
          font-extrabold
          text-gray-900
          sm:text-5xl
          md:text-6xl
        "
      >
        <span class="block">Choose Your </span>
        <span class="block text-mygrad">Graduation Keepsake</span>
      </h1>
      <p
        class="
          mt-3
          max-w-md
          mx-auto
          text-base text-gray-700 text-xl
          sm:text-lg
          md:mt-5 md:text-xl md:max-w-3xl
        "
      >
        Celebrate your special day with our exclusive Graduation products. Choose from a digital download, a personalised clip, or a bundle.
      </p>
      <button v-if="this.$route.params.uni == 'nottingham'"
        type="button"
        @click="goToLink"
        class="
          inline-flex
          mt-3
          items-center
          px-4
          py-2
          border border-transparent
          shadow-sm
          text-lg
          font-medium
          rounded-md
          text-white
          bg-mygrad
          hover:bg-mygrad-dark
          focus:outline-none
          focus:ring-2
          focus:ring-offset-2
          focus:ring-mygrad
        "
      >
        For ceremonies before July 2024
      </button>
    </div>
    <div class="grid grid-cols-3 md:hidden gap-2 mt-8">
      <div class="flex flex-col items-center border p-0 rounded-lg shadow-md  bg-white ">
        <img :src="require('@/assets/personalised-graduation-clip.jpg')" @click="clip" alt="Personalised Clip Image" class="w-auto h-auto object-contain mb-4 rounded-lg">
        <p class="mt-0 text-lg font-bold text-gray-700">Price: £20</p> 
        </div>
        <div class="flex flex-col items-center border p-0 rounded-lg shadow-md  bg-white">
          <img :src="require('@/assets/digital-film-download-colour.jpg')" @click="digitalDownload"  alt="Digital Download Image" class="w-auto h-auto object-contain mb-4 rounded-lg">
          <p class="mt-0 text-lg font-bold text-gray-700">Price: £30</p>
        </div>
        <div class="flex flex-col items-center border p-0 rounded-lg shadow-md  bg-white">
          <img :src="require('@/assets/personalised-graduation-clip-and-download.jpg')" @click="bundle" alt="Bundle Image" class="w-auto h-auto object-contain mb-4 rounded-lg">
          <p class="mt-0 text-lg font-bold text-gray-700">Price: £{{ this.$route.params.uni === 'nottingham' ? '35' : '40' }}</p>
          </div>
      </div>
    <div class="grid grid-cols-1 md:grid-cols-3 gap-8 mt-8">

      <!-- Product 2: Personalised Clip -->
      <div class="flex flex-col items-center border p-4 rounded-lg shadow-md pb-8 bg-white">
        <img :src="require('@/assets/personalised-graduation-clip.jpg')" @click="clip" alt="Personalised Clip Image" class="w-auto h-64 object-contain mb-4 rounded-lg">
        <h2 class="text-2xl font-bold text-gray-900 text-center">Personalised Graduation Clip</h2>
        <p class="mt-2 text-base text-gray-700 text-xl text-center">
          Receive a personalised GraduationClip of your graduation ceremony, highlighting only your special moment.
        </p>
        <p class="mt-2 text-lg font-bold text-gray-700">Price: £20</p>
        <button
          type="submit"
          @click="clip"
          class="
            inline-flex
            mt-3
            items-center
            px-4
            py-2
            border border-gray-300
            shadow-sm
            text-lg
            font-medium
            rounded-md
            text-gray-700
            bg-white
            hover:bg-gray-50
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-indigo-500
          "
        >
          Buy now
        </button>
      </div>
      <!-- Product 1: Digital Download -->
      <div class="flex flex-col items-center border p-4 rounded-lg shadow-md pb-8 bg-white">
        <img :src="require('@/assets/digital-film-download-colour.jpg')" @click="digitalDownload"  alt="Digital Download Image" class="w-auto h-64 object-contain mb-4 rounded-lg">
        <h2 class="text-2xl font-bold text-gray-900">Digital Download</h2>
        <p class="mt-2 text-base text-gray-700 text-xl text-center">
          A Digital Download provides full coverage of your graduation ceremony, capturing all graduates, speeches and your moment as you cross the centre stage.
        </p>
        <p class="mt-2 text-lg font-bold text-gray-700">Price: £30</p>
        <button
          type="submit"
          @click="digitalDownload"
          class="
            inline-flex
            mt-3
            items-center
            px-4
            py-2
            border border-gray-300
            shadow-sm
            text-lg
            font-medium
            rounded-md
            text-gray-700
            bg-white
            hover:bg-gray-50
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-indigo-500
          "
        >
          Buy now
        </button>
      </div>


      <!-- Product 3: Bundle -->
      <div class="flex flex-col items-center border p-4 rounded-lg shadow-md pb-8 bg-white">
        <img :src="require('@/assets/personalised-graduation-clip-and-download.jpg')" @click="bundle" alt="Bundle Image" class="w-auto h-64 object-contain mb-4 rounded-lg">
        <h2 class="text-2xl font-bold text-gray-900">Graduation Bundle</h2>
        <p class="mt-2 text-base text-gray-700 text-xl text-center">
          Enjoy the best of both worlds with our Graduation Bundle. Includes both the digital download and the personalised clip.
        </p>
        <p class="mt-2 text-lg font-bold text-gray-700">Price: £{{ this.$route.params.uni === 'nottingham' ? '35' : '40' }}</p>
        <button
          type="submit"
          @click="bundle"
          class="
            inline-flex
            mt-3
            items-center
            px-4
            py-2
            border border-gray-300
            shadow-sm
            text-lg
            font-medium
            rounded-md
            text-gray-700
            bg-white
            hover:bg-gray-50
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-indigo-500
          "
        >
          Buy now
        </button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "NottinghamListingPage",
  props: { title: String, organiserName: String, organiserSlug: String},
  data: function () {
    return {
      form: {
        name: "",
      },
    };
  },
  methods: {
    submit() {
      this.$emit("submit", this.form);
    },
    digitalDownload() {
      this.$router.push({path: `/store/${this.$route.params.uni}/purchase/digital-download`})
    },
    clip() {
      this.$router.push({path: `/store/${this.$route.params.uni}/purchase`})
    },
    bundle() {
      this.$router.push({path: `/store/${this.$route.params.uni}/purchase?bundle=True`})
    },
    goToLink() {
      window.location.href = "https://shop2.mygraduationfilm.com/collections/nottingham-university"
    }
  },
};
</script>
