<template>
    <div class="group">
          <div
            class="
              w-full
              aspect-w-1 aspect-h-1
              rounded-lg
              overflow-hidden
              sm:aspect-w-16 sm:aspect-h-9
            "
          >
            <img
              :src="getSrc()"
              class="
                w-full
                h-full
                object-center object-cover"
            />
          </div>
          <div
            class="
              mt-4
              flex
              items-center
              justify-between
              text-base
              font-medium
              text-gray-900
            "
          >
          <button
          @click="thumbnailClicked"
          type="submit"
          class="
            inline-flex
            items-center
            px-4
            py-2
            border border-gray-300
            shadow-sm
            text-sm
            font-medium
            rounded-md
            text-gray-700
            bg-white
            hover:bg-gray-50
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-indigo-500
            disabled:opacity-50!important
          "
        >
          This is me
        </button>
          </div>
          
          <p class="mt-1 text-sm italic text-gray-700">{{confidence * 100 | round(1)}} % confidence</p>
          </div>
</template>
<script>

export default {
  name: "SingleThumbnail",
  props: {confidence: Number, url: String, text: String, src: String, hash: String, gifSrc : String},
  methods: {
    thumbnailClicked() {
      this.$emit('clickThumbnail', this.hash)
    },

    getSrc() {
      if (this.gifSrc !== null) {
        return this.gifSrc;
      }
      return this.src;
    }
  }
};
</script>