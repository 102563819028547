<template>

  <div class="md:w-1/2 sm:w-full mx-auto">
    <div class="flex flex-wrap -mx-2 space-y-4 md:space-y-0">
      <div class="mt-8 mx-auto max-w-7xl px-4 sm:mt-4 sm:px-6">
        <div class="text-center">
          <h1
            class="
              text-4xl
              tracking-tight
              font-extrabold
              text-gray-900
              sm:text-5xl
              md:text-6xl
            "
          >
            <span class="block">Get your </span>
            <span class="block text-mygrad">Personalised GraduationClip</span>
          </h1>

          <p
            class="
              mt-3
              max-w-md
              mx-auto
              text-base text-gray-700 text-xl
              sm:text-lg
              md:mt-5 md:text-xl md:max-w-3xl
            "
          >
            Graduation day at <b>{{ organiserName }}</b> is an important moment to
            celebrate, and we know you're ready to share it with the world!
          </p>
          <p
            v-if="organiserSlug == 'bristol' || organiserSlug == 'ulster' || organiserSlug == 'city-london'"
            class="
              mt-3
              max-w-md
              mx-auto
              text-base text-gray-700 text-xl
              sm:text-lg
              md:mt-5 md:text-xl md:max-w-3xl
            "
          >
            Watch a sample GraduationClip.
          </p>
          <div
            v-if="organiserSlug == 'bristol'"
            class="
              mt-3
              max-w-md
              mx-auto
              text-base text-gray-700 text-xl
              sm:text-lg
              md:mt-5 md:text-xl md:max-w-3xl
            "
          >
            <div style="padding: 56.25% 0 0 0; position: relative">
              <iframe
                src="https://player.vimeo.com/video/719163247?h=5e84a744e0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowfullscreen
                style="
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                "
                title="MyGraduationClip"
              ></iframe>
            </div>
          </div>
          <div
            v-if="organiserSlug == 'ulster'"
            class="
              mt-3
              max-w-md
              mx-auto
              text-base text-gray-700 text-xl
              sm:text-lg
              md:mt-5 md:text-xl md:max-w-3xl
            "
          >
            <div style="padding: 56.25% 0 0 0; position: relative">
              <iframe
                src="https://player.vimeo.com/video/778964708?h=1e06115b45&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowfullscreen
                style="
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                "
                title="MyGraduationClip"
              ></iframe>
            </div>
          </div>
          <div
            v-if="organiserSlug == 'city-london'"
            class="
              mt-3
              max-w-md
              mx-auto
              text-base text-gray-700 text-xl
              sm:text-lg
              md:mt-5 md:text-xl md:max-w-3xl
            "
          >
            <div style="padding: 56.25% 0 0 0; position: relative">
              <iframe
                src="https://player.vimeo.com/video/904145077?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowfullscreen
                style="
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                "
                title="MyGraduationClip"
              ></iframe>
            </div>
          </div>
          <div
            v-if="organiserSlug == 'roehampton'"
            class="
              mt-3
              max-w-md
              mx-auto
              text-base text-gray-700 text-xl
              sm:text-lg
              md:mt-5 md:text-xl md:max-w-3xl
            "
          >
            <div style="padding: 56.25% 0 0 0; position: relative">
              <iframe
                src="https://player.vimeo.com/video/908149069?h=d839a73f66&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowfullscreen
                style="
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                "
                title="MyGraduationClip"
              ></iframe>
            </div>
          </div>
          <button
            type="submit"
            @click="$emit('submit', form)"
            class="
              inline-flex
              mt-3
              items-center
              px-4
              py-2
              border border-gray-300
              shadow-sm
              text-lg
              font-medium
              rounded-md
              text-gray-700
              bg-white
              hover:bg-gray-50
              focus:outline-none
              focus:ring-2
              focus:ring-offset-2
              focus:ring-indigo-500
            "
          >
            Order your GraduationClip now
          </button>

          <p
            class="
              mt-3
              max-w-md
              mx-auto
              text-lg text-base text-gray-700
              sm:text-lg
              md:mt-5 md:text-xl md:max-w-3xl
            "
          >
            We're excited to introduce our new GraduationClip - a
            <b>short video of your graduation ceremony</b> that you can share on
            social media, or keep on hand to relive your special moment whenever
            you want.
          </p>

          <p
            class="
              mt-6
              max-w-md
              mx-auto
              text-base text-gray-700
              sm:text-lg
              md:mt-5 md:text-xl md:max-w-3xl
            "
          >
            You'll get a link in your email after the ceremony, so you can post
            it online and share all the fun details of your big day with
            everyone who couldn't be there. We hope this will help you relive
            the magic of your graduation celebration every time you watch it!
          </p>
          <button v-if="organiserSlug == 'bristol'"
            type="submit"
            @click="$emit('submit', form)"
            class="
              inline-flex
              mt-3
              items-center
              px-4
              py-2
              border border-gray-300
              shadow-sm
              text-lg
              font-medium
              rounded-md
              text-gray-700
              bg-white
              hover:bg-gray-50
              focus:outline-none
              focus:ring-2
              focus:ring-offset-2
              focus:ring-indigo-500
            "
          >
            Buy now - £10
          </button>
          <button v-else-if="organiserSlug == 'ulster'"
            type="submit"
            @click="$emit('submit', form)"
            class="
              inline-flex
              mt-3
              items-center
              px-4
              py-2
              border border-gray-300
              shadow-sm
              text-lg
              font-medium
              rounded-md
              text-gray-700
              bg-white
              hover:bg-gray-50
              focus:outline-none
              focus:ring-2
              focus:ring-offset-2
              focus:ring-indigo-500
            "
          >
            Buy now - £15
          </button>
          <button v-else-if="organiserSlug == 'city-london'"
            type="submit"
            @click="$emit('submit', form)"
            class="
              inline-flex
              mt-3
              items-center
              px-4
              py-2
              border border-gray-300
              shadow-sm
              text-lg
              font-medium
              rounded-md
              text-gray-700
              bg-white
              hover:bg-gray-50
              focus:outline-none
              focus:ring-2
              focus:ring-offset-2
              focus:ring-indigo-500
            "
          >
            Buy now - £20
          </button>
           <button v-else
            type="submit"
            @click="$emit('submit', form)"
            class="
              inline-flex
              mt-3
              items-center
              px-4
              py-2
              border border-gray-300
              shadow-sm
              text-lg
              font-medium
              rounded-md
              text-gray-700
              bg-white
              hover:bg-gray-50
              focus:outline-none
              focus:ring-2
              focus:ring-offset-2
              focus:ring-indigo-500
            "
          >
            Buy now
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "IndividualSalesPage",
  props: { title: String, organiserName: String, organiserSlug: String},
  data: function () {
    return {
      form: {
        name: "",
      },
    };
  },
  methods: {
    submit() {
      this.$emit("submit", this.form);
    },
  },
};
</script>
