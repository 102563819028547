<template>
  <div>
    <div class="md:w-1/2 sm:w-full mx-auto">
    <div class="flex flex-wrap -mx-2 space-y-4 md:space-y-0">
      <div class="mt-8 mx-auto max-w-7xl">
        <AudioToolLoading v-if="this.audioState == 'loading_xyz'" />
        <div v-else class="text-center w-full h-auto">
          <h1
            class="
              text-4xl
              tracking-tight
              font-extrabold
              text-gray-900
              sm:text-5xl
              md:text-6xl
            "
          >
            <span class="block">Perfect the audio on your</span>
            <span class="block text-mygrad">GraduationClip</span>
          </h1>
          <p
            class="
              mt-3
              max-w-md
              mx-auto
              text-base text-gray-700
              sm:text-lg
              md:mt-5 md:text-xl md:max-w-3xl
            "
          >
          The waveform below features a <b>highlighted box</b>, which precisely corresponds to your video segment chosen.<br>

          </p>
          <p class="
              mt-3
              max-w-md
              mx-auto
              text-base text-gray-700
              sm:text-lg
              md:mt-5 md:text-xl md:max-w-3xl
            ">Your audio may have been announced at an earlier or later time.</p>
            <p
            class="
              mt-3
              max-w-md
              mx-auto
              text-base text-gray-700
              sm:text-lg
              md:mt-5 md:text-xl md:max-w-3xl
            "
          >
            Drag the highlighted box to select your audio, you can move the edges of the box to fine tune the start and end points.
          </p>
          <p
            class="
              mt-3
              max-w-md
              mx-auto
              text-base text-gray-700
              sm:text-lg
              md:mt-5 md:text-xl md:max-w-3xl
            "
          >
            (Each time you move the box, the audio will be played from where you have selected the start until where you have selected the end. Please listen to the audio before submitting.)
          </p>
        </div>
      </div>
      <div v-if="this.errors.length" class="mt-8 mx-auto max-w-7xl">
        <div class="text-center w-full h-auto">
          <p
            class="
              mt-3
              max-w-md
              mx-auto
              text-base text-red-500
              sm:text-lg
              md:mt-5 md:text-xl md:max-w-3xl
            "
          >
            {{ this.errors[0] }}
          </p>
        </div>
      </div>
      <div id="waveform"></div>
      <div class="mt-8 mx-auto max-w-7xl">
       
        <div class="text-center w-full h-auto">
          <button v-if="this.audioState == 'ready'" @click="playPreview" type="submit"
          class="
            inline-flex
            items-center
            px-4
            py-2
            border border-gray-300
            shadow-sm
            text-sm
            font-medium
            rounded-md
            text-gray-700
            bg-white
            hover:bg-gray-50
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-indigo-500
            disabled:opacity-50!important
            disabled:hidden!important
          "
          :disabled="!(this.audioState == 'ready')"
          >Preview Audio</button>
          <button v-if="this.audioState == 'playing'" @click="pausePreview" type="submit"
          class="
            inline-flex
            items-center
            px-4
            py-2
            border border-gray-300
            shadow-sm
            text-sm
            font-medium
            rounded-md
            text-gray-700
            bg-white
            hover:bg-gray-50
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-indigo-500
            disabled:opacity-50!important
            disabled:hidden!important
          "
          :disabled="!(this.audioState == 'playing')"
          >Pause</button>
        </div>
      </div>
      <div class="text-center w-full h-auto">
        <button
          @click="submit"
          type="submit"
          class="
            inline-flex
            items-center
            px-4
            py-2
            border border-gray-300
            shadow-sm
            text-sm
            font-medium
            rounded-md
            text-gray-700
            bg-white
            hover:bg-gray-50
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-indigo-500
            disabled:opacity-50!important
            disabled:hidden!important
          "
          :disabled="!(this.previewed)"
        >
          Submit
        </button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import WaveSurfer from 'wavesurfer.js'
import Regions from 'wavesurfer.js/dist/plugins/regions.js'
import Minimap from 'wavesurfer.js/dist/plugins/minimap.js'
import AudioToolLoading from './AudioToolLoading.vue'

export default {
  name: "AudioSubmission",
  props: {
    focalPoint: Number,
    ceremony: Object,
    errors: Array,
    audio: String,
    peaks: Object
  },
  data: function () {
    return {
      audiofile: this.audio,
      hasMovedIn: false,
      hasMovedOut: false,
      min: Math.max(this.focalPoint - 2, 0),
      max: this.focalPoint + 1,
      surfer: null,
      regions: [],
      audioState: "loading",
      previewed: false,
    };
  },
  mounted() {
    this.surfer = WaveSurfer.create({
        container: '#waveform',
        waveColor: 'rgb(207,217,237)',
        progressColor: '#60A5FA',
        backend: "MediaElement",
        hideScrollbar: true,
        normalize: true,
        autoplay: false,
        interact: false,
        minPxPerSec: 25,
        autoScroll: true,
        autoCentre: true,
        fillParent: true,
        // barRadius: 50,
        // barGap: 50,
        // barHeight: 50,
      })
      this.minimap = this.surfer.registerPlugin( Minimap.create({
            height: 20,
            waveColor: '#ddd',
            progressColor: '#999',
            // the Minimap takes all the same options as the WaveSurfer itself
          }))
      this.regions = this.surfer.registerPlugin(Regions.create())
      if (this.peaks == null) {
        this.surfer.load(this.audiofile)
      }
      else {
        this.surfer.load(this.audiofile, this.peaks.data)
      }
      this.surfer.on('play', () => {
        this.audioState = "playing";
      });
      this.surfer.on('pause', () => {
        this.audioState = "ready";
      });

      this.surfer.on('ready', () => {
        this.surfer.setTime(this.min)
        this.audioState = "ready";
        this.regions.addRegion({
          start: this.min,
          end: this.max,
          content: 'My Name',
          drag: true,
          resize: true,
          maxLength: 17,
          minLength: 1
        });
      });
      this.regions.on('region-updated', (region) => {
        console.log('Updated region', region)
        this.min = region.start;
        this.max = region.end;
        console.log(this.min, this.max);
        this.previewed = false;
        region.play();
      })
      this.regions.on('region-clicked', (region, e) => {
        e.stopPropagation() // prevent triggering a click on the waveform
        region.play()
      })
      this.regions.on('region-out', () => {
        this.surfer.pause();
      })
  },
  computed: {

    player() {
      return this.$refs.audio_comp;
    },
    minThreshold() {
      return this.focalPoint - 2;
    },
    maxThreshold() {
      return this.focalPoint + 1;
    },
  },
  methods: {
    pausePreview() {
      this.surfer.pause();
    },
    playPreview() {
      this.regions.regions[0].play();
      this.previewed = true;
    },
    onPlayerReady() {
      console.log("Pkayer Ready");
      this.playerReady = true;
      this.player.pause();
      this.audiofile = this.audio + "#t="+this.min + "," + this.max;
      this.surfer.load(this.audiofile)
    },
    submit() {
      this.$emit("submit", {
        min: this.min,
        max: this.max,
      });
    },
  },
  components: {
    AudioToolLoading
  },
};
</script>

<style>
video {
  min-width: 100%;
  position: relative !important;
}

.video-js .vjs-time-divider {
  display: none !important;
}
button:disabled {
  visibility: hidden;
}
#waveform {
  width: 100% !important;
}
</style>