<template>
  <div class="md:w-1/2 sm:w-full mx-auto">
    <div class="flex flex-wrap -mx-2 space-y-4 md:space-y-0" v-if="error == ''">
      <div class="mt-8 mx-auto max-w-7xl px-4 sm:mt-4 sm:px-6">
        <div class="text-center">
          <h1
            class="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl"
          >
            <span class="block">Congratulations</span>
          </h1>
          <h1
            class="text-5xl tracking-tight font-extrabold text-gray-900 sm:text-2xl md:text-4xl mt-4 mb-2"
          >
            <span class="block text-mygrad">{{ clip.registration.name }}</span>
          </h1>
          <div id="video-container">
          <p
            class="mt-3 max-w-md mx-auto text-base text-gray-700 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl"
          >
            <video
              autoplay
              controls
              ref="video"
              :src="clip.url"
              :poster="clip.poster"
              preload="metadata"
              playsinline
              @pause="
                trackEvent($route.path, {
                  label: 'VIDEO_PAUSE',
                  type: 'GRADAUTIONCLIP',
                  submission_id: $route.params.id,
                  registration_id: clip.registration.id,
                })
              "
              @play="
                trackEvent($route.path, {
                  label: 'VIDEO_PLAY',
                  type: 'GRADAUTIONCLIP',
                  submission_id: $route.params.id,
                  registration_id: clip.registration.id,
                })
              "
              @ended="
                trackEvent($route.path, {
                  label: 'VIDEO_ENDED',
                  type: 'GRADAUTIONCLIP',
                  submission_id: $route.params.id,
                  registration_id: clip.registration.id,
                })
              "
            />
          </p>
          <br />
          <div  v-show="showModal" class="modal">
            <!-- Modal content with share buttons -->
            <div class="modal-content">
              <span class="close" @click="closeModal">&times;</span>
              <h2
                class="text-2xl tracking-tight font-extrabold text-gray-900 sm:text-2xl md:text-2xl"
              >
                <span class="block text-mygrad">Share your GraduationClip now!</span>
              </h2>
              <div
                class="a2a_kit a2a_kit_size_64 a2a_default_style"
                data-a2a-title="MyGraduationClip"
                id="shareButtons"
              >
                <a class="a2a_button_whatsapp"></a>
                <a class="a2a_button_linkedin"></a>
                <a class="a2a_button_facebook"></a>
                <a class="a2a_button_twitter"></a>
                <a class="a2a_button_wechat"></a>
                <a class="a2a_dd" href="https://www.addtoany.com/share"></a>
              </div>
            </div>
          </div>
          <div  v-show="showModalPurchase" class="modal" style="z-index:999;">
            <!-- Modal content with share buttons -->
            <div class="modal-content">
              <span class="close" @click="closeModalPurchase">&times;</span>
              <h2
                class="text-2xl tracking-tight font-extrabold text-gray-900 sm:text-2xl md:text-2xl"
              >
                <span class="block text-mygrad">Why not also get the Full Length Graduation film?</span>
              </h2>
              <button
                type="submit"
                :disabled=purchaseButtonDisabled
                @click="buyCeremony"
                class="text-center mt-3 items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 margin-sm"
                style="background-color: #0d9b00;color: #fff;font-size: large;padding: 1em;"
                >
                <b>{{purchaseButtonText}}</b>
              </button>
            </div>
          </div>
          </div>
          <h2
            class="text-2xl tracking-tight font-extrabold text-gray-900 sm:text-2xl md:text-2xl"
          >
            <span class="block text-mygrad-light">{{ organiser.name }}</span>
          </h2>
          <div
            class="mt-3 max-w-md mx-auto text-base text-gray-700 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl flex justify-center items-center"
          >
            <div
              class="a2a_kit a2a_kit_size_32 a2a_default_style"
              data-a2a-title="MyGraduationClip"
              id="shareButtons"
            >
              <a class="a2a_button_whatsapp"></a>
              <a class="a2a_button_linkedin"></a>
              <a class="a2a_button_facebook"></a>
              <a class="a2a_button_twitter"></a>
              <a class="a2a_button_wechat"></a>
              <a class="a2a_dd" href="https://www.addtoany.com/share"></a>
            </div>
          </div>
          <div
            class="mt-3 max-w-md mx-auto text-base text-gray-700 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl grid grid-cols-1 md:grid-cols-3 gap-2 content-center"
          >
            <button
              type="submit"
              @click="download"
              class="text-center mt-3 items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 margin-sm"
            >
              Download my GraduationClip
            </button>
            <button
              type="submit"
              @click="referAFriend"
              class="text-center mt-3 items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 margin-sm"
            >
              Refer a friend
            </button>
            <!--
            <button
              type="submit"
              @click="changeClip"
              class="text-center mt-3 items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Edit my GraduationClip
            </button>
            <button
              type="submit"
              @click="changeAudio"
              class="text-center mt-3 items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Adjust my sound
            </button>
          -->
            <button
              type="submit"
              @click="faqHelp"
              class="text-center mt-3 items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Help me - FAQs!
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-wrap -mx-2 space-y-4 md:space-y-0" v-if="error != ''">
      <div class="mt-8 mx-auto max-w-7xl px-4 sm:mt-4 sm:px-6">
        <div class="text-center">
          <h1
            class="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl"
          >
            <span class="block">Your clip is not ready yet!</span>
          </h1>
          <h1
            class="text-5xl tracking-tight font-extrabold text-gray-900 sm:text-2xl md:text-4xl mt-4 mb-2"
          >
            <span class="block text-mygrad">Come back in a few minutes.</span>
          </h1>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="https://static.addtoany.com/menu/page.js"></script>

<script>
import axios from "axios";
import { trackEvent } from "../Analytics.vue";

export default {
  name: "DeliveryPage",
  props: { organiser: Object },
  data: function () {
    return {
      clip: {},
      error: "",
      showModal: false,
      purchaseButtonText: "Get the full ceremony film!",
      purchaseButtonDisabled: false,
      showModalPurchase: false,
      digitalDownloadAvailable: false,
    };
  },
  beforeCreate() {
    const clipId = this.$route.params.id;
    axios
      .get(`/clips/${clipId}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((response) => response.data)
      .then((response_data) => this.setData(response_data))
      .catch(
        (error) => (
          (this.error = error.response.error),
          trackEvent(this.$route.path, {
            label: "GRADUATIONCLIP_NOT_READY",
            submission_id: this.$route.params.id,
          })
        )
      );
  },
  methods: {
    checkVideoProgress() {
      const videoElement = this.$refs.video; // Add a ref="video" to your video tag
      if (videoElement) {
        const currentTime = videoElement.currentTime;
        const duration = videoElement.duration;
        if (currentTime / duration > 0.85) {
          // Example: 85% of the video has played
          this.showModal = true;
          if (this.digitalDownloadAvailable) {
            this.showModalPurchase = true;
            trackEvent(this.$route.path, {
              label: "DIGITAL_DOWNLOAD_PURCHASE_MODAL_OPENED",
              registration_id: this.clip.registration.id,
              submission_id: this.$route.params.id,
            });
          }
          clearInterval(this.videoCheckInterval);
          trackEvent(this.$route.path, {
            label: "SHARE_MODAL_OPENED",
            registration_id: this.clip.registration.id,
            submission_id: this.$route.params.id,
          }); 
          
        }
      }
    },
    closeModalPurchase() {
      this.showModalPurchase = false;
      trackEvent(this.$route.path, {
            label: "DIGITAL_DOWNLOAD_PURCHASE_MODAL_CLOSED",
            registration_id: this.clip.registration.id,
            submission_id: this.$route.params.id,
          });  
    },
    closeModal() {
      this.showModal = false;
      trackEvent(this.$route.path, {
            label: "SHARE_MODAL_CLOSED",
            registration_id: this.clip.registration.id,
            submission_id: this.$route.params.id,
          });  
    },
    download() {
      const clipId = this.$route.params.id;
      const token = localStorage.getItem("token");
      trackEvent(this.$route.path, {
        label: "DOWNLOAD_CLIP",
        registration_id: this.clip.registration.id,
        submission_id: this.$route.params.id,
      });
      window.open(
        `${process.env.VUE_APP_BASE_URL}/clips/${clipId}/download?authToken=${token}`,
        "_blank"
      );
    },
    referAFriend() {
      trackEvent(this.$route.path, {
        label: "REFER_CLICK",
        registration_id: this.clip.registration.id,
        submission_id: this.$route.params.id,
      });
      window.open("https://mygraduationclip.com/#links", "_blank");
    },
    setData(clip) {
      this.clip = clip;
      this.clip.submission.id = this.$route.params.id;
      trackEvent(this.$route.path, {
        label: "GRADUATIONCLIP_VIEW",
        registration_id: this.clip.registration.id,
        submission_id: this.$route.params.id,
      });
    },
    changeClip() {
      trackEvent(this.$route.path, {
        label: "CHANGE_CLIP_CLICK",
        registration_id: this.clip.registration.id,
        submission_id: this.$route.params.id,
      });
      this.$router.push(
        `/submission/${this.clip.registration.id}?uni=${this.organiser.slug}&timecode=${this.clip.submission.in_point}`
      );
    },
    changeAudio() {
      trackEvent(this.$route.path, {
        label: "CHANGE_AUDIO_CLICK",
        registration_id: this.clip.registration.id,
        submission_id: this.$route.params.id,
      });
      window.open(
        `/clip/${this.organiser.slug}/${this.$route.params.id}/audio`
      );
    },
    buyCeremony() {
      if (this.purchaseButtonDisabled) {
        return;
      }
      this.purchaseButtonText = "Taking you there...";
      this.purchaseButtonDisabled = false;
      window.open(
        `/store/${this.organiser.slug}/purchase/digital-download`
      );
      return;
      axios({"method": "POST",
        "baseURL":  process.env.VUE_APP_DIGITAL_DOWNLOAD_BASE_URL,
        "url": "/purchase",
          "data": {
            name: this.clip.registration.name,
            email: this.clip.registration.email,
            ceremony: this.clip.registration.ceremony
          }
        })
        .then((r) => {
          this.url = r.data;
          trackEvent(this.$route.path, {label: "DIGITAL_DOWNLOAD_CLIP_VIEW_PURCHASE_CLICK", paid: false})
          window.location.href = r.data;
        });
    },
    faqHelp() {
      trackEvent(this.$route.path, {
        label: "FAQ_HELP_CLICK",
        registration_id: this.clip.registration.id,
        submission_id: this.$route.params.id,
      });
      window.open(`/clip/${this.organiser.slug}/${this.$route.params.id}/help`);
    },
    trackEvent,
  },
  mounted() {
    axios({"method": "GET",
        "baseURL":  process.env.VUE_APP_DIGITAL_DOWNLOAD_BASE_URL,
        "url": `/availability/${this.organiser.id}`
        })
        .then(() => {
          this.digitalDownloadAvailable = true;
        }).catch(() => {
          this.digitalDownloadAvailable = false;
        });
    this.videoCheckInterval = setInterval(this.checkVideoProgress, 1000); // Check every second

    const recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute(
      "src",
      "https://static.addtoany.com/menu/page.js"
    );
    document.head.appendChild(recaptchaScript);
    let recaptchaScriptIsReady = false;
    var self = this;

    let checkInterval = setInterval(
      function () {
        console.log("Checking");
        if (typeof window !== "undefined" && window.a2a) {
          window.a2a_config = window.a2a_config || {};
          window.a2a_config.templates = window.a2a_config.templates || {};

          window.a2a_config.callbacks = window.a2a_config.callbacks || [];
          window.a2a_config.templates.whatsapp = {
            text: "Watch my personalised GraduationClip from " +this.organiser.name+ " ${link}",
          };
          window.a2a_config.templates.sms = {
            body: "Watch my personalised GraduationClip from " +this.organiser.name+ " ${link}",
          };
          window.a2a_config.templates.email = {
            text: "Watch my personalised GraduationClip from " +this.organiser.name+ " ${link}",
          };
          window.a2a_config.templates.twitter = {
            text: "Watch my personalised GraduationClip from " +this.organiser.name+ " ${link}",
            related: "MyGraduationClip,GraduationClip,Graduation",
          };
          window.a2a_config.templates.linkedin = {
            text: "I'm incredibly proud to share my personalised GraduationClip from " +this.organiser.name+ " ${link}",
          };

          window.a2a_config.callbacks = [
            {
              share: (e) => {
                console.log(e);
                trackEvent(window.location.pathname, {
                  label: "SHARE",
                  service: e.serviceCode,
                  url: e.url,
                  title: e.title,
                  service_readable_name: e.service,
                  registration_id: self.clip.registration.id,
                  submission_id: self.clip.submission.id,
                });
              },
            },
          ];
          recaptchaScriptIsReady = true;
          clearInterval(checkInterval);

          window.a2a.init_all();
        }
        return recaptchaScriptIsReady;
      }.bind(self),
      2000
    );
  },
  components: {},
  beforeDestroy() {
    clearInterval(this.videoCheckInterval);
  },
};
</script>
<style scoped>
#shareButtons {
  display: flex;
  padding: 10px !important;
  justify-content: center !important;
  flex-wrap: wrap;
}
.a2a_default_style:not(.a2a_flex_style) a {
  margin: 4px 0;
}
.modal {
  position: absolute; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: #daf6ff; /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */

}

.modal-content {
  background-color: #daf6ff;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
  border: 2px solid #08697b; /* Black border, you can change the color */
  border-radius: 10px; /* Rounded corners */
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
.video-container {
    position: relative;
}
</style>