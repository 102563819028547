<template>
  <div class="text-center">
    <h1
      class="
        text-4xl
        tracking-right
        font-extrabold
        text-gray-900
        sm:text-5xl
        md:text-6xl
      "
    >
      <span class="block">Success,</span>
      <span class="block text-mygrad">we're all set!</span>
    </h1>
    <p
      class="
        mt-3
        max-w-md
        mx-auto
        text-base
        text-gray-700
        sm:text-lg
        md:mt-5
        md:text-xl
        md:max-w-3xl 
      "
    >
      Thank you for purchasing your Personalised GraduationClip. We'll
      send an email to {{ email }} with further information after your
      ceremony.
    </p>
  </div>
</template>
  
<script>
export default {
  name: 'MyGraduationClipSuccessMessage',
  props: {
      email: String
  }
};
</script>