<template>
  <div>
    <div v-if="state == 'not-finished'" class="md:w-1/2 sm:w-full mx-auto">
      <div class="flex flex-wrap -mx-2 space-y-4 md:space-y-0">
        <div class="mt-8 mx-auto max-w-7xl px-4 sm:mt-4 sm:px-6">
          <div class="text-center">
            <h1
              class="
                text-2xl
                tracking-tight
                font-extrabold
                text-gray-900
                sm:text-2xl
                md:text-2xl
              "
            >
              <span class="block text-mygrad"
                >Let's complete your purchase</span
              >
            </h1>
             <p
            class="
              mt-3
              max-w-md
              mx-auto
              text-base text-gray-700 text-xl
              sm:text-lg
              md:mt-5 md:text-xl md:max-w-3xl
            "
          >
            You purchased a personalised clip at your Graduation ceremony, we need a few more details to make the best clip possible.
          </p>
          </div>
        </div>
      </div>
      <ValidationObserver v-slot="{ handleSubmit }">
        <form
          class="space-y-4 text-gray-700"
          @submit.prevent="handleSubmit(submit)"
        >
          <div class="flex flex-wrap -mx-2 space-y-4 md:space-y-0">
            <div class="w-full px-2">
              <ValidationProvider
                rules="required|min:3"
                v-slot="{ errors, valid }"
              >
                <label class="block mb-1" for="formGridCode_name"
                  >Full name</label
                >
                <input
                  class="
                    w-full
                    h-10
                    px-3
                    text-base
                    placeholder-gray-600
                    border
                    rounded-lg
                    focus:shadow-outline
                  "
                  type="text"
                  id="formGridCode_name"
                  name="name"
                  v-model="form.name"
                  placeholder="Your Name to be shown at graduation"
                  v-bind:class="{
                    'border-red-700 text-red-900': errors.length > 0,
                    'border-green-700': valid,
                  }"
                />
              </ValidationProvider>
            </div>
          </div>

          <div
            v-for="(field, index) in additionalFieldsInput"
            :key="field.name"
            class="flex flex-wrap -mx-2 space-y-4 md:space-y-0"
          >
            <div class="w-full px-2">
              <ValidationProvider
                :rules="field.rules"
                v-slot="{ valid, errors }"
              >
                <label class="block mb-1" :for="field.name">{{
                  field.title
                }}</label>
                <div v-if="field.type == 'radio'">
                  <div
                    v-for="(choice, c_index) in field.choices"
                    :key="c_index"
                  >
                    <label :for="choice.value">{{ choice.text }} </label>

                    <input
                      type="radio"
                      v-model="form.additionalFields[index].value"
                      :id="choice.value"
                      :name="field.name"
                      :value="choice.value"
                    />
                  </div>
                </div>
                <div v-else-if="field.type == 'select'">
                  <select
                    :id="field.name"
                    :name="field.name"
                    class="
                      w-full
                      h-10
                      px-3
                      text-base
                      placeholder-gray-600
                      border
                      rounded-lg
                      focus:shadow-outline
                    "
                    v-model="form.additionalFields[index].value"
                  >
                    <option selected disabled :value="null">
                      {{ field.default }}
                    </option>

                    <option
                      v-for="(choice, c_index) in field.choices"
                      :key="c_index"
                      :value="choice.value"
                    >
                      {{ choice.text }}
                    </option>
                  </select>
                </div>
                <input
                  v-else
                  class="
                    w-full
                    h-10
                    px-3
                    text-base
                    placeholder-gray-600
                    border
                    rounded-lg
                    focus:shadow-outline
                  "
                  :type="field.type"
                  :id="field.name"
                  :name="field.name"
                  :placeholder="field.placeholder"
                  v-model="form.additionalFields[index].value"
                  v-bind:class="{
                    'border-red-700 text-red-900': errors.length > 0,
                    'border-green-700': valid,
                  }"
                />
              </ValidationProvider>
            </div>
          </div>

          <button
            type="submit"
            class="
              inline-flex
              items-center
              px-4
              py-2
              border border-gray-300
              shadow-sm
              text-sm
              font-medium
              rounded-md
              text-gray-700
              bg-white
              hover:bg-gray-50
              focus:outline-none
              focus:ring-2
              focus:ring-offset-2
              focus:ring-indigo-500
            "
          >
            Submit
          </button>
        </form>
      </ValidationObserver>
    </div>
    <Finished v-if="state == 'finished'" :name="firstname(form.name)" />
    <Error v-if="state=='error'" :message="errorMessage" />
  </div>
</template>

<script>
import { extend } from "vee-validate";
import { required, min, max, regex } from "vee-validate/dist/rules";
import axios from "axios";
import Finished from "../../Registration/Finished.vue";
import {endSession, trackEvent} from "../../../Analytics.vue"
import Error from "./Error.vue";

extend("required", {
  ...required,
  message: "This field is required",
});
extend("min", min);

extend("regex", regex);
extend("max", max);
export default {
  name: "CompleteShopifyReg",
  props: { order_id: String },
  data: function () {
    return {
      form: {
        name: "",
        event: null,
        faculty: null,
        additionalFields: [],
      },
      additionalFieldsInput: [],
      state: "not-finished",
      errorMessage: ""
    };
  },
  created: function () {
    axios.get(`/purchase/shopify/${this.$route.params.id}`).then((r) => {
      this.order_id = this.$route.params.id;
      this.form.additionalFields = r.data.university.additionalFields;
      this.additionalFieldsInput = r.data.university.additionalFields;
      this.form.additionalFields.map((item) => {
        if (item.regex != null) {
          item.regex = new RegExp(item.regex);
        }
      });
    }).catch((error) => (this.state = "error", this.errorMessage = error.response.data.error));

  },
  methods: {
    firstname(name) {
      return name.split(" ")[0];
    },
    submit() {
      console.log(this.form);
      var mappedAdditionalFields = {};
      this.form["additionalFields"].forEach((item) => {
        mappedAdditionalFields[item["name"]] = item["value"];
      });
      axios
        .post("/purchase/shopify", {
          shopify_id: this.order_id,
          name: this.form["name"],
          additionalFields: mappedAdditionalFields,
        })
        .then((r) => {
          console.log(r.data);
          trackEvent(this.$route.path, {label: "SHOPIFY_PURHCASE_COMPLETE", shopify_id: this.order.id, registration_id: r.data.id})
          if (r.data.ceremony_ready) {
            this.$router.push(`/thumbnails/${this.$route.params.uni}/${r.data.id}`)
          }
          this.state = "finished";
          endSession();
        });
    },
    getRulesObject(field) {
      return field.rules;
    },
  },
  components: {
    Finished,
    Error
  },
};
</script>
