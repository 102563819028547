<template>
    <div class="md:w-1/2 sm:w-full mx-auto">
      <div class="flex flex-wrap -mx-2 space-y-4 md:space-y-0">
        <div class="mt-8 mx-auto max-w-7xl px-4 sm:mt-4 sm:px-6">
          <div class="text-center">
            <h1
              class="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl"
            >
              <span class="block">FAQs</span>
            </h1>
            <h1
              class="text-5xl tracking-tight font-extrabold text-gray-900 sm:text-2xl md:text-4xl mt-4 mb-2"
            >
              <span class="block text-mygrad">{{ clip.registration.name }}</span>
            </h1>
            <h2
              class="text-2xl tracking-tight font-extrabold text-gray-900 sm:text-2xl md:text-2xl"
            >
              <span class="block text-mygrad-light">{{ organiser.name }}</span>
            </h2>
            <div
              class="mt-3  grid grid-cols-1 md:grid-cols-1 gap-2 content-center"
            >
            <div class="mt-4 p-4 text-sm font-medium rounded-md text-gray-700 bg-white">
                <h3 class="text-lg font-medium text-gray-900">My GraduationClip needs adjusting - its too early / too late</h3>
                <p class="mt-2 text-gray-700">You can adjust the timing of your GraduationClip by clicking the button below, carefully follow the instructions presented on screen!</p>
                <button
                type="submit"
                @click="changeClip"
                class="text-center mt-3 items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Edit my GraduationClip
              </button>
            </div>

            <div class="mt-4 p-4 text-sm font-medium rounded-md text-gray-700 bg-white">
                <h3 class="text-lg font-medium text-gray-900">The Audio is cut off on my GraduationClip</h3>
                <p class="mt-2 text-gray-700">You can adjust the timing of the audio in your GraduationClip by clicking the button below, carefully follow the instructions presented on screen!</p>
                <button
                type="submit"
                @click="changeAudio"
                class="text-center mt-3 items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Adjust my sound
              </button>
            </div> 

            <div class="mt-4 p-4 text-sm font-medium rounded-md text-gray-700 bg-white">
                <h3 class="text-lg font-medium text-gray-900">I made a mistake with the spelling of my name, faculty or course</h3>
                <p class="mt-2 text-gray-700">You can edit your name, faculty and course by clicking on the button below - we will automatically regenerate your video afterwards.</p>
                <button
                type="submit"
                @click="changeName"
                class="text-center mt-3 items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Fix a spelling mistake
              </button>
            </div>
            </div>
          </div>
        </div>
      </div>
     
    </div>
  </template>
  
  <script>
  import axios from "axios";
  import { trackEvent } from "../../Analytics.vue";
  
  export default {
    name: "Help",
    props: { organiser: Object },
    data: function () {
      return {
        clip: {},
      };
    },
    beforeCreate() {
      const clipId = this.$route.params.id;
      axios
        .get(`/clips/${clipId}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => response.data)
        .then((response_data) => this.setData(response_data))
        .catch(
          (error) => (
            (this.error = error.response.error),
            trackEvent(this.$route.path, {
              label: "GRADUATIONCLIP_NOT_READY",
              submission_id: this.$route.params.id,
            })
          )
        );
    },
    methods: {
      setData(clip) {
        this.clip = clip;
        this.clip.submission.id = this.$route.params.id;
        trackEvent(this.$route.path, {
          label: "FAQS_PAGE_VIEW",
          registration_id: this.clip.registration.id,
          submission_id: this.$route.params.id,
        });
      },
      changeClip() {
        trackEvent(this.$route.path, {
          label: "CHANGE_CLIP_CLICK",
          registration_id: this.clip.registration.id,
          submission_id: this.$route.params.id,
        });
        this.$router.push(
          `/submission/${this.clip.registration.id}?uni=${this.organiser.slug}&timecode=${this.clip.submission.in_point}`
        );
      },
      changeAudio() {
        trackEvent(this.$route.path, {
          label: "CHANGE_AUDIO_CLICK",
          registration_id: this.clip.registration.id,
          submission_id: this.$route.params.id,
        });
        window.open(
          `/clip/${this.organiser.slug}/${this.$route.params.id}/audio`
        );
      },
      changeName() {
        trackEvent(this.$route.path, {
          label: "UPDATE_DETAILS_CLICK",
          registration_id: this.clip.registration.id,
          submission_id: this.$route.params.id,
        });
        window.open(
          `/${this.organiser.slug}/registration/${this.clip.registration.id}/update`
        );
      },
      
     
      trackEvent,
    },
    mounted() {
      
    },
    components: {},
    beforeDestroy() {
      
    },
  };
  </script>
  <style scoped>
  #shareButtons {
    display: flex;
    padding: 10px !important;
    justify-content: center !important;
    flex-wrap: wrap;
  }
  .a2a_default_style:not(.a2a_flex_style) a {
    margin: 4px 0;
  }
  .modal {
    position: absolute; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: #daf6ff; /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  
  }
  
  .modal-content {
    background-color: #daf6ff;
    margin: 15% auto; /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    width: 80%; /* Could be more or less, depending on screen size */
    border: 2px solid #08697b; /* Black border, you can change the color */
    border-radius: 10px; /* Rounded corners */
  }
  
  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  .video-container {
      position: relative;
  }
  </style>