<template>
  <div class="md:w-1/2 sm:w-full mx-auto">
    <div class="flex flex-wrap -mx-2 space-y-4 md:space-y-0">
      <div class="mt-16 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6">
        <div class="text-center">
          <h1
            class="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl"
          >
            <span class="block">That's it {{ name }},</span>
            <span class="block text-mygrad">we're all set!</span>
          </h1>
          <p
            class="mt-3 max-w-md mx-auto text-base text-gray-700 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl"
          >
            Thank you for registering for your Personalised GraduationClip. You will receive further information by email after your ceremony.
          </p>
           <p
            class="mt-3 max-w-md mx-auto text-base text-gray-700 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl"
          >
            Your registration number is: <b>{{registrationID}}</b> - please take note of this in case you need to contact us.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Finished",
  props: { name: String, registrationID : String }
};
</script>
